// src/utils/utils.js
import React, { useEffect } from "react";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend dayjs with UTC and timezone plugins
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Checks if the current window is inside an iframe.
 * @returns {boolean} True if the window is in an iframe, otherwise false.
 */
export function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true; // Assume it's in an iframe if there's an error
  }
}

/**
 * Capitalize the first letter of a given string.
 * @param {string} string - The input string.
 * @returns {string} - The string with the first letter capitalized.
 */
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Generate a gradient based on the puzzle pack number.
 * @param {number} packNumber - The pack number.
 * @returns {string} - The generated gradient string.
 */
export function getPackGradient(packNumber) {
  return `linear-gradient(135deg, hsl(${packNumber * 40}, 70%, 60%), hsl(${
    packNumber * 40 + 30
  }, 70%, 60%))`;
}

/**
 * Format time in mm:ss format.
 * @param {number} seconds - The total time in seconds.
 * @returns {string} - The formatted time string.
 */
export function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const roundedSeconds = Math.round(remainingSeconds);
  return `${String(minutes).padStart(2, '0')}:${String(roundedSeconds).padStart(2, '0')}`;
}

/**
 * Calculate the countdown time to the next midnight in the Arizona timezone.
 * @returns {string} - The remaining time as hh:mm:ss.
 */
export function calculateCountdown() {
  const now = dayjs().tz("America/Phoenix");
  const nextMidnight = dayjs().tz("America/Phoenix").endOf('day');
  const diff = nextMidnight.diff(now, 'second');

  const hours = Math.floor(diff / 3600).toString().padStart(2, '0');
  const minutes = Math.floor((diff % 3600) / 60).toString().padStart(2, '0');
  const seconds = (diff % 60).toString().padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
}

/**
 * Generate a random UUID.
 * @returns {string} - The generated UUID string.
 */
export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 * Retrieve or create an anonymous user ID stored in localStorage.
 * @returns {string} - The retrieved or newly created user ID.
 */
export function getOrCreateAnonymousUserId() {
  let userId;
  try {
    userId = localStorage.getItem('anonymousUserId');
    if (!userId) {
      userId = generateUUID(); 
      localStorage.setItem('anonymousUserId', userId);
    }
  } catch (e) {
    userId = "NoUserId";
  }
  return userId;
}

/**
 * Get the current referrer URL.
 * @returns {string} - The current page's URL.
 */
export function getReferrer() {
  try {
    if (document.referrer) {
      return document.referrer;
    }
  } catch (error) {
  }
  try {
    return window.top.location.href;
  } catch (error) {
  }
  return window.location.href;
}


/**
 * Get the browser's user agent string.
 * @returns {string} - The user agent string.
 */
export function getBrowserType() {
  return navigator.userAgent;
}

/**
 * Check if a puzzle is completed based on the puzzle type and number.
 * @param {Array} puzzles - Array of completed puzzles.
 * @param {string} puzzleType - The type of the puzzle (e.g., 'daily', 'pack', 'daily-double').
 * @param {number} puzzleNumber - The number of the puzzle.
 * @returns {boolean} - True if the puzzle is completed, false otherwise.
 */
export function isPuzzleCompleted(puzzles, puzzleType, puzzleNumber) {
  return puzzles.some(
    (entry) => entry.puzzleType === puzzleType && entry.puzzleNumber === puzzleNumber
  );
}

/**
 * Store the puzzle start data in the backend.
 * @param {string} puzzleType - The type of the puzzle.
 * @param {number} puzzleNumber - The number of the puzzle.
 * @param {boolean} isDarkMode - Whether dark mode is enabled.
 * @param {boolean} isSoundOn - Whether sound is enabled (DEPRECATED)
 */
export async function handleStorePuzzleStart(puzzleType = 'Unknown', puzzleNumber = 0, isDarkMode = false, isSoundOn = true) {
  const userId = getOrCreateAnonymousUserId();
  const referrer = getReferrer() || 'NoReferrer';
  const screenWidth = window.innerWidth || 0;
  const screenHeight = window.innerHeight || 0;
  const browserType = getBrowserType() || 'UnknownBrowser';

  const payload = {
    user_id: userId,
    referrer: referrer,
    puzzle_type: puzzleType,
    puzzle_number: puzzleNumber,
    screen_width: screenWidth,
    screen_height: screenHeight,
    browser_type: browserType,
    is_dark_mode: isDarkMode,
    is_sound_on: isSoundOn,
  };

  try {
    const response = await fetch('/api/puzzle-store/puzzle-start', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const result = await response.json();
    console.log('Puzzle start data stored successfully:', result);
  } catch (error) {
    console.error('Failed to store puzzle start data:', error);
  }
}

/**
 * Store the completed puzzle data in the backend.
 * @param {string} puzzleType - The type of the puzzle.
 * @param {number} puzzleNumber - The number of the puzzle.
 * @param {number} timeInSeconds - The time taken to complete the puzzle.
 * @param {boolean} isDarkMode - Whether dark mode is enabled.
 * @param {boolean} isSoundOn - Whether sound is enabled (DEPRECATED).
 */
export async function handleStoreCompletedPuzzle(puzzleType = 'Unknown', puzzleNumber = 0, timeInSeconds = 0, isDarkMode = false, isSoundOn = true) {
  const userId = getOrCreateAnonymousUserId() || 'NoUserId';
  const referrer = getReferrer() || 'NoReferrer';
  const screenWidth = window.innerWidth || 0;
  const screenHeight = window.innerHeight || 0;
  const browserType = getBrowserType() || 'UnknownBrowser';

  const payload = {
    user_id: userId,
    referrer: referrer,
    puzzle_type: puzzleType,
    puzzle_number: puzzleNumber,
    time_in_seconds: timeInSeconds,
    screen_width: screenWidth,
    screen_height: screenHeight,
    browser_type: browserType,
    is_dark_mode: isDarkMode,
    is_sound_on: isSoundOn,
  };

  try {
    const response = await fetch('/api/puzzle-store/puzzle-completed', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const result = await response.json();
    console.log('Puzzle completed data stored successfully:', result);
  } catch (error) {
    console.error('Failed to store puzzle completed data:', error);
  }
}


export function detectDeviceInputType() {
  // Check if touch events are supported
  const hasTouchEvents = "ontouchstart" in window || navigator.maxTouchPoints > 0;

  // Check if the user is using a touch device (pointer: coarse) or desktop (pointer: fine)
  const isTouchDevice = window.matchMedia("(pointer: coarse)").matches;

  return hasTouchEvents || isTouchDevice ? "touch" : "mouse";
}

export function addBlueHue(color) {
  // Assume color is a hex like #RRGGBB
  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);

  // Mix a little more blue
  const newB = Math.min(b + 50, 255); // Add a slight blue hue, max out at 255

  return `rgb(${r}, ${g}, ${newB})`;
};


export function isWinterSeason() {
  const today = new Date();

  // Hard-coded winter season dates of promo
  const startWinter = new Date(2024, 11, 2); // December 2, 2024
  const endWinter = new Date(2025, 2, 20);   // March 20, 2025

  // Check if the current date is in winter season during promp
  if (today >= startWinter && today <= endWinter) {
    return true;
  }
  return false;
};



/////// ADS
// Ad slots: horizontal: "4258199429", vertical: "2945117752", square: "8634195188", fixed-banner:1382422947
export function GoogleAd({ dataAdSlot }) {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("AdSense failed to load:", e);
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-9824715749253729"
      data-ad-slot={dataAdSlot}
      data-ad-format="auto"
      data-full-width-responsive="true"
    />
  );
}

export function GoogleAdResponsiveMobile({ dataAdSlot }) {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("AdSense failed to load:", e);
    }
  }, []);

  return (
    <>
      <style>
        {`
          .my_responsive_ad {
            width: 320px; 
            height: 60px;  
          }
        `}
      </style>
      <ins
        className="adsbygoogle my_responsive_ad"
        style={{ display: "block" }}
        data-ad-client="ca-pub-9824715749253729"
        data-ad-slot={dataAdSlot}
      />
    </>
  );
}


export function GoogleAdResponsiveDesktop({ dataAdSlot }) {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("AdSense failed to load:", e);
    }
  }, []);

  return (
    <>
      <style>
        {`
          .my_responsive_ad {
            width: 728px; 
            height: 90px;  
          }
        `}
      </style>
      <ins
        className="adsbygoogle my_responsive_ad"
        style={{ display: "block" }}
        data-ad-client="ca-pub-9824715749253729"
        data-ad-slot={dataAdSlot}
      />
    </>
  );
}



export function GoogleAdResponsiveDesktopSquare({ dataAdSlot }) {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("AdSense failed to load:", e);
    }
  }, []);

  return (
    <>
      <style>
        {`
          .my_responsive_ad_square {
            width: 300px;
            height: 250px;  
          }
        `}
      </style>
      <ins
        className="adsbygoogle my_responsive_ad_square"
        style={{ display: "block" }}
        data-ad-client="ca-pub-9824715749253729" 
        data-ad-slot={dataAdSlot}
      />
    </>
  );
}




