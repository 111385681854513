// src/components/Puzzle.js

import React, { useEffect, useState, useRef, useContext } from "react";
import { Box, Typography } from "@mui/material";
import Loading from "../components/Loading";
import CluesContainer from "./CluesContainer";
import ControlBox from "./ControlBox";
import GameWonModal from "../components/GameWonModal";
import usePuzzleData from "../hooks/usePuzzleData";
import useTimer from "../hooks/useTimer";
import useGameState from "../hooks/useGameState";
import { useControlHandlers } from "../hooks/useControlHandlers";
import useSyllableHandlers from "../hooks/useSyllableHandlers";
import { useThemeContext } from '../contexts/ThemeContext';
import { useMediaContext } from '../contexts/MediaContext';
import { PuzzleDataContext } from '../contexts/PuzzleDataContext';
import { handleStorePuzzleStart, handleStoreCompletedPuzzle } from '../utils/utilityFunctions';
import { useLocation } from 'react-router-dom';
import useDailyPuzzleNumber from '../hooks/useDailyPuzzleNumber';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { isInIframe } from '../utils/utilityFunctions';

import useArenaHelper from '../hooks/useArenaHelper';

const Puzzle = ({
  archivePuzzleNo = null,
  packPuzzleNo = null,
  setSelectedPuzzleNo,
  puzzleType
}) => {
  const { addPuzzleCompletion, puzzleData: completedPuzzles } = useContext(PuzzleDataContext); // renames puzzleDat to completedPuzzles
  const { isDarkMode, currentTheme } = useThemeContext();
  
  const { isMobile } = useMediaContext();
  const location = useLocation();
  const { height: windowHeight } = useWindowDimensions();
  const { handleMidrollRequest } = useArenaHelper();
  
  const shouldFetchDailyPuzzleNo = (puzzleType === 'daily' || puzzleType === 'daily-double') && archivePuzzleNo != null;
  const { todayPuzzleNo, todayDailyDoublePuzzleNo } = useDailyPuzzleNumber(shouldFetchDailyPuzzleNo);


  const shouldShowMobileAds =  !isInIframe() && isMobile && windowHeight >= 640;
  // const shouldShowDesktopAds = !isInIframe() && !isMobile;

  const {
    puzzleData,
    loading,
    error,
    availableSyllables,
    clueStates,
    setClueStates,
    setAvailableSyllables,
    fetchPuzzleData,
  } = usePuzzleData(puzzleType, archivePuzzleNo, packPuzzleNo);

  const { gameStarted, setGameStarted, gameWon, setGameWon, selectedSyllable, setSelectedSyllable } = useGameState(clueStates);
  const [timer, setTimer] = useTimer(gameStarted, gameWon);
  const [controlBoxHeight, setControlBoxHeight] = useState(250);
  const [modalOpen, setModalOpen] = useState(false);
  const [isPuzzleCompleted, setIsPuzzleCompleted] = useState(false);
  const controlBoxRef = useRef(null);

  const currentPuzzleNumber = archivePuzzleNo || packPuzzleNo || (puzzleData ? puzzleData.puzzle_no : null);

  const resetTrigger = location.state?.resetTrigger;


  const resetPuzzle = () => {
    // Reset states only if necessary
    setClueStates([]);
    setAvailableSyllables([]);
    setSelectedSyllable(null);
    setGameStarted(false);
    setGameWon(false);
    setTimer(0);
    setModalOpen(false);
  };

  useEffect(() => {
    fetchPuzzleData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [puzzleType, archivePuzzleNo, packPuzzleNo, resetTrigger]);

  useEffect(() => {
    if (resetTrigger !== undefined) {
      resetPuzzle();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetTrigger]);


  useEffect(() => {
    if (gameWon) {
      
      try {
        const completionTime = timer;
        const puzzleNumber = currentPuzzleNumber;
        const storagePuzzleType = ((puzzleType === 'daily' || puzzleType === 'daily-double') && archivePuzzleNo != null)
        ? `archive|${puzzleType}`
        : puzzleType;

        const isPuzzleCompleted = completedPuzzles.some(
          (entry) => entry.puzzleType === puzzleType && entry.puzzleNumber === currentPuzzleNumber
        );
        setIsPuzzleCompleted(isPuzzleCompleted)

        // Guardrail: Only store the puzzle if completionTime is greater than 5 seconds
        if (completionTime > 5) {
          handleStoreCompletedPuzzle(storagePuzzleType, puzzleNumber, completionTime, isDarkMode);
          addPuzzleCompletion(puzzleType, puzzleNumber, completionTime);
        } else {
          console.log('Puzzle completion not stored: Completion time must be greater than 10 seconds.');
        }

        setModalOpen(true);
      } catch (error) {
        console.error("Error adding puzzle completion:", error);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameWon]);

  const handlePreviousPuzzle = () => {
    const prevPuzzleNo = currentPuzzleNumber - 1;
    if (prevPuzzleNo > 0 && setSelectedPuzzleNo) {
      resetPuzzle();
      setSelectedPuzzleNo(prevPuzzleNo);
  
    // Arena: handleMidrollRequest
    handleMidrollRequest();
    }
  };
  
  const handleNextPuzzle = () => {
    const nextPuzzleNo = currentPuzzleNumber + 1;

    if ((puzzleType === 'pack' && nextPuzzleNo <= 900) || 
        (puzzleType === 'daily' && archivePuzzleNo != null && nextPuzzleNo < todayPuzzleNo) ||
        (puzzleType === 'daily-double' && archivePuzzleNo != null && nextPuzzleNo < todayDailyDoublePuzzleNo) 
        ) {
      if (setSelectedPuzzleNo) {
        resetPuzzle();
        setSelectedPuzzleNo(nextPuzzleNo);
  
        // Arena: handleMidrollRequest
        handleMidrollRequest();
        }
    }
  };
  

  const { handleShuffle, handleSort, handleClear } = useControlHandlers(
    setClueStates,
    setAvailableSyllables,
    setSelectedSyllable,
    availableSyllables,
    selectedSyllable
  );

  const { handleSyllableClick } = useSyllableHandlers(
    setClueStates,
    setAvailableSyllables,
    setSelectedSyllable,
    availableSyllables,
    selectedSyllable
  );

  useEffect(() => {
    if (controlBoxRef.current) {
      let height = controlBoxRef.current.clientHeight;
  
      // Add 60px to height if puzzleType is  from archive or "pack"
      if (((puzzleType === 'daily' && archivePuzzleNo != null) || 
            (puzzleType === 'daily-double' && archivePuzzleNo != null)  || 
            puzzleType === 'pack') && gameWon) {
        height += 30;
      }
  
      setControlBoxHeight(height);
    }
  }, [availableSyllables, controlBoxRef, gameWon, puzzleType]);


  const cluesContainerHeight = isMobile
  ? (shouldShowMobileAds
      ? `calc(${windowHeight}px - ${controlBoxHeight}px - 125px)` // Mobile + Ads
      : `calc(${windowHeight}px - ${controlBoxHeight}px - 60px)`  // Mobile, no Ads
    )
  : "90%"; // Non-mobile

  const prevPuzzleNumber = currentPuzzleNumber - 1;
  const nextPuzzleNumber = currentPuzzleNumber + 1;

  const isPrevPuzzleCompleted = completedPuzzles.some(
    (entry) => entry.puzzleType === puzzleType && entry.puzzleNumber === prevPuzzleNumber
  );

  const isNextPuzzleCompleted = completedPuzzles.some(
    (entry) => entry.puzzleType === puzzleType && entry.puzzleNumber === nextPuzzleNumber
  );

  const isPrevDisabled = currentPuzzleNumber <= 1;
  const isNextDisabled =
    (puzzleType === 'pack' && currentPuzzleNumber >= 900) || 
    (puzzleType === 'daily' && archivePuzzleNo != null && currentPuzzleNumber >= todayPuzzleNo - 1) ||
    (puzzleType === 'daily-double' && archivePuzzleNo != null && currentPuzzleNumber >= todayDailyDoublePuzzleNo - 1);

  if (loading) {
    return <Loading message={" Puzzle"} />;
  }

  if (error) {
    return <Typography>{error}</Typography>;
  }

  if (!puzzleData) {
    return <Typography>Error loading puzzle data</Typography>;
  }

  return (
    <Box
      sx={{
        color: currentTheme.textColor,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: isMobile ? "block" : "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          overflow: "hidden",
          gap: isMobile ? "0px" : "10px",
        }}
      >
        {/* Clues Container */}
        <Box
          sx={{
            order: isMobile ? 1 : 2,
            flexGrow: isMobile ? 1 : 0,
            width: "100%",
            height: cluesContainerHeight,
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CluesContainer
            clues={clueStates}
            puzzleType={puzzleType}
            puzzleNumber={currentPuzzleNumber}
            gameStarted={gameStarted}
            timer={timer}
            selectedSyllable={selectedSyllable}
            setSelectedSyllable={setSelectedSyllable}
            onStart={() => {
              const storagePuzzleType = 
              (puzzleType === 'daily' || puzzleType === 'daily-double') && archivePuzzleNo != null
                ? `archive|${puzzleType}`
                : puzzleType;
              handleStorePuzzleStart(storagePuzzleType, currentPuzzleNumber, isDarkMode);
              setGameStarted(true);
              setSelectedSyllable("0-0");
            }}
          />
        </Box>

        {/* Control Box */}
        <Box
          ref={controlBoxRef}
          sx={{
            order: isMobile ? 2 : 1,
            position: isMobile ? "fixed" : "relative",
            bottom: isMobile ? "0px" : "auto",
            left: isMobile ? "50%" : "auto",
            transform: isMobile ? "translateX(-50%)" : "none",
            zIndex: 1000,
            paddingBottom: isMobile ? "10px" : "0px",
            display: "flex",
            justifyContent: "center",
            maxHeight: isMobile ? "none" : "90%"
          }}
        >
          <ControlBox
            syllables={availableSyllables}
            onShuffle={handleShuffle}
            onSort={handleSort}
            onClear={handleClear}
            gameStarted={gameStarted}
            handleSyllableButtonClick={handleSyllableClick}
            gameWon={gameWon}
            puzzleType={puzzleType}
            handlePreviousPuzzle={handlePreviousPuzzle}
            handleNextPuzzle={handleNextPuzzle}
            isPrevDisabled={isPrevDisabled}
            isNextDisabled={isNextDisabled}
            prevPuzzleNumber={prevPuzzleNumber}
            nextPuzzleNumber={nextPuzzleNumber}
            isPrevPuzzleCompleted={isPrevPuzzleCompleted}
            isNextPuzzleCompleted={isNextPuzzleCompleted}
            archivePuzzleNo={archivePuzzleNo}
          />
        </Box>
      </Box>
      {/* Game Won Modal */}
      {puzzleData && puzzleType && (
        <GameWonModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          timer={timer}
          puzzleNumber={currentPuzzleNumber}
          puzzleType={puzzleType}
          handlePreviousPuzzle={handlePreviousPuzzle}
          handleNextPuzzle={handleNextPuzzle}
          isPrevDisabled={isPrevDisabled}
          isNextDisabled={isNextDisabled}
          prevPuzzleNumber={prevPuzzleNumber}
          nextPuzzleNumber={nextPuzzleNumber}
          isPrevPuzzleCompleted={isPrevPuzzleCompleted}
          isNextPuzzleCompleted={isNextPuzzleCompleted}
          isPuzzleCompleted={isPuzzleCompleted} 
          archivePuzzleNo={archivePuzzleNo}
        />
      )}
    </Box>
  );
};

export default Puzzle;