// src/components/PuzzleLayout.js

import React from 'react';
import { Box } from '@mui/material';
import Puzzle from '../components/Puzzle';
import { useThemeContext } from '../contexts/ThemeContext';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { useLocation } from 'react-router-dom';
import { useMediaContext } from '../contexts/MediaContext';
import { isInIframe } from '../utils/utilityFunctions';
import { GoogleAdResponsiveMobile, GoogleAdResponsiveDesktop, GoogleAdResponsiveDesktopSquare } from '../utils/utilityFunctions';

const PuzzleLayout = ({archivePuzzleNo = null, packPuzzleNo = null, setSelectedPuzzleNo, puzzleType }) => {
  const { currentTheme } = useThemeContext();
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  const { isMobile } = useMediaContext();
  const location = useLocation();
  const shouldShowMobileAds =  !isInIframe() && isMobile && windowHeight >= 640;
  const shouldShowDesktopAds = !isInIframe() && !isMobile;


  if (!shouldShowMobileAds && !shouldShowDesktopAds) {
    // Original Layout (No Ads)
    return (
      <Box
        sx={{
          color: currentTheme.textColor,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Game Area */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: windowHeight - 70,
            overflow: 'hidden',
          }}
        >
          {/* Puzzle Goes Here */}
          <Puzzle
            archivePuzzleNo={archivePuzzleNo}
            packPuzzleNo={packPuzzleNo}
            setSelectedPuzzleNo={setSelectedPuzzleNo}
            puzzleType={puzzleType}
          />
        </Box>
      </Box>
    );
  }

  if (shouldShowMobileAds) {
  
    return (
      <Box
        sx={{
          color: currentTheme.textColor,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Ad Banner Above */}
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'auto', 
          overflow: 'hidden',
          backgroundColor: 'transparent',
          marginBottom: "5px"
        }}
      >
        <GoogleAdResponsiveMobile dataAdSlot="4258199429" />
      </Box>
  
        {/* Game Area */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: windowHeight - 130, // Subtract the ad height
            overflow: 'hidden',
          }}
        >
          <Puzzle
            archivePuzzleNo={archivePuzzleNo}
            packPuzzleNo={packPuzzleNo}
            setSelectedPuzzleNo={setSelectedPuzzleNo}
            puzzleType={puzzleType}
          />
        </Box>
      </Box>
    );
  }

  if (shouldShowDesktopAds) {
  
    // Decide if there's enough space for side ads (700 for puzzle + 2×300 for side ads)
    const canShowSideAds = windowWidth > 1438; 
  
    return (
      <Box
        sx={{
          color: currentTheme.textColor,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Top: Puzzle + optional side ads */}
        <Box
          sx={{                
            display: 'flex',
            justifyContent: 'center',     
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          {/* Left Square Ad (shown only if canShowSideAds) */}
          {canShowSideAds && (
            <Box
              sx={{
                width: 300,
                height: 250,
                backgroundColor: 'transparent', 
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: "69px", // Some spacing to puzzle
              }}
            >
              <GoogleAdResponsiveDesktopSquare dataAdSlot="8634195188" />
            </Box>
          )}
  
          {/* Puzzle (700px wide) */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: windowHeight - 160,
              overflow: 'hidden',
            }}
          >
            <Puzzle
              archivePuzzleNo={archivePuzzleNo}
              packPuzzleNo={packPuzzleNo}
              setSelectedPuzzleNo={setSelectedPuzzleNo}
              puzzleType={puzzleType}
            />
          </Box>
  
          {/* Right Square Ad (shown only if canShowSideAds) */}
          {canShowSideAds && (
            <Box
              sx={{
                width: 300,
                height: 250,
                backgroundColor: 'transparent',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: "69px",
              }}
            >
              <GoogleAdResponsiveDesktopSquare dataAdSlot="8634195188" />
            </Box>
          )}
        </Box>
  
        {/* Bottom Banner (728×90) */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'auto',
            overflow: 'hidden',
            backgroundColor: 'transparent',
            marginBottom: '5px',
          }}
        >
          <GoogleAdResponsiveDesktop dataAdSlot="4258199429" />
        </Box>
      </Box>
    );
  }




};

export default PuzzleLayout;